// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Navbar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Navbar.tsx");
  import.meta.hot.lastModified = "1715943395329.381";
}
// REMIX HMR END

import { mockData } from '@/lib/mockData';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from "@remix-run/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export default function Navbar(data) {
  const navigation = mockData.navigation;
  const currentSlug = data.params ? data.params.slug : true;
  const darkTheme = true;
  return <Disclosure as="nav" className="bg-white">
      {({
      open
    }) => <div>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-between">
                <div className="flex flex-shrink-0 items-center text-xl font-black sm:text-white">
                  <a href="/" className='text-black'>
                    BedriftsFinans
                  </a>
                </div>
                <div className="hidden sm:ml-6 sm:flex">
                  <div className="flex">
                    {navigation.map(item => <Link key={item.id} to={item.slug} className={classNames('text-emerald-700 expand-border ', 'rounded-md px-3 py-2 text-sm font-bold')} aria-current={currentSlug === item.slug ? 'page' : undefined}>
                        {item.title}
                      </Link>)}
                  </div>
                  <div className='w-fit'>
                    <button className="bg-emerald-700 text-white rounded-md px-3 py-2 mx-2 text-sm font-medium">Søk nå</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map(item => <Disclosure.Button key={item.id} as="a" href={item.slug} className={classNames(currentSlug === item.slug ? 'bg-slate-600 text-white' : 'text-white', 'block rounded-md px-3 py-2 text-base font-medium')} aria-current={currentSlug === item.slug ? 'page' : undefined}>
                  {item.title}
                </Disclosure.Button>)}
            </div>
          </Disclosure.Panel>
        </div>}
    </Disclosure>;
}
_c = Navbar;
var _c;
$RefreshReg$(_c, "Navbar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;